import Txt, { TxtProps } from '@componentPrimitives/Txt';
import { useEventPerformances } from '@lib/hooks';
import { fmtDateRange, fmtTimeRange } from '@lib/utils/format';
import { VTPerformance } from '@lib/vivaticket/parse/parsePerformanceList';
import { CircularProgress, Stack } from '@mui/material';

export interface EventPerformanceProps extends TxtProps {
  data?: VTPerformance | null;
}

const EventPerformance = ({ data, ...props }: EventPerformanceProps) => {
  if (!data) return null;

  const { timeStart: timestampStart, timeEnd: timestampEnd } = data;

  const dateStr = fmtDateRange(timestampStart, timestampEnd);
  const timeStr = fmtTimeRange(timestampStart, timestampEnd);

  return (
    <Txt variant="body" cx={{ mT: 'none' }} {...props}>
      {dateStr}
      <br />
      {timeStr}
    </Txt>
  );
};

export interface EventPerformancesProps {
  eventAk?: string | null;
  description?: string;
}

const EventPerformances = ({ eventAk, description }: EventPerformancesProps) => {
  const { loading, items } = useEventPerformances(eventAk);
  return (
    <Stack>
      <Txt variant="body" cx={{ mB: 'sm' }}>
        {description}
      </Txt>

      {loading && (
        <Stack /* alignItems="center" */ mb={4}>
          <CircularProgress color="primary" size={30} />
        </Stack>
      )}

      {items?.map((v, i) => (
        <EventPerformance key={i} data={v} />
      ))}
    </Stack>
  );
};

export default EventPerformances;
